import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { Application, CHARGE_ACCOUNT_PRODUCT_ID } from 'types/schemas';
import { KovoError } from 'libs/KovoError';

export const USE_APPLICATIONS_QUERY_KEY = 'applications';

export type GetApplicationsResponse = {
  applications: Application[];
};

type UseGetApplicationsResponse = GetApplicationsResponse & {
  chargeAccountApplication?: Application;
};

function useGetApplications() {
  const { identityId, username } = useContext(AuthContext);

  const getApplication = async () => {
    try {
      const res: GetApplicationsResponse = await API.get(
        'clientsService',
        `/v1/applications`,
        {
          headers: {
            ...amplifyRequestContext(identityId, username, {
              'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
              'x-user-id': identityId,
            }).headers,
          },
        },
      );

      /**
       * The issue with this is that we will want to be able to support multiple
       * charge account applications where all but one are in a completed state
       * and then there is one that is currently in progress. For now, this is
       * fine.
       */
      const chargeAccountApplication = res.applications.find(
        ({ loanProductId, status }) =>
          status !== 'abandoned' && loanProductId === CHARGE_ACCOUNT_PRODUCT_ID,
      );

      return { ...res, chargeAccountApplication };
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error getting applications', { error });
    }
  };

  return useQuery<UseGetApplicationsResponse, KovoError>(
    [USE_APPLICATIONS_QUERY_KEY],
    () => getApplication(),
  );
}

export default useGetApplications;
