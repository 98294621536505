import Rollbar from 'rollbar';

const environment = import.meta.env.VITE_STAGE || '';
const accessToken = import.meta.env.VITE_ROLLBAR_TOKEN || '';
const rollbarEnabled =
  import.meta.env.VITE_ENABLE_ROLLBAR === 'true' &&
  !!import.meta.env.VITE_ROLLBAR_TOKEN;

const rollbar = new Rollbar({
  enabled: rollbarEnabled,
  accessToken,

  captureUncaught: true,
  addErrorContext: true,
  captureUnhandledRejections: true,

  /** also log Rollbar calls to console */
  verbose: environment !== 'prod',

  scrubFields: [
    'ssn',
    'phone',
    'birthday',
    'address',
    'address2',
    'state',
    'city',
    'zipcode',
    'monthlyIncome',
  ],

  scrubTelemetryInputs: true,

  checkIgnore(isUncaught, args, payload) {
    /**
     * The typing on this argument is invalid. It is not an array.
     * It can be spread, but array methods are not defined. This line
     * converts the args into an array.
     */
    const argsArray = [...args];

    /**
     * This error is thrown when Google Maps does not find results and the user
     * clicks away from the input. It does not affect user experience but creates
     * noise in Rollbar.
     */
    const isGoogleMapsError = argsArray.some((arg) => arg === 'ZERO_RESULTS');

    if (isGoogleMapsError) {
      return true;
    }

    return false;
  },

  payload: {
    environment,
    client: {
      javascript: {
        code_version: import.meta.env.VITE_COMMIT_REF,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
});

export { rollbar, rollbarEnabled };
