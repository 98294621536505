import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { ROUTES } from 'components/Routes';
import { Application } from 'types/schemas';
import { USE_APPLICATIONS_QUERY_KEY } from 'hooks/queries/useGetApplications';
import { KovoError } from 'libs/KovoError';

function useStartApplication() {
  const { identityId, username } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const history = useHistory();

  const startApplication = async (application?: Application) => {
    try {
      if (!application) {
        throw new Error('Application is undefined');
      }

      const res = await API.post(
        'clientsService',
        `/v1/applications/${application.sk}/started`,
        {
          body: {},
          headers: {
            ...amplifyRequestContext(identityId, username, {
              'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
              'x-user-id': identityId,
              'content-type': 'application/json',
            }).headers,
          },
        },
      );

      return res;
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error starting application', {
        error,
      });
    }
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATIONS_QUERY_KEY],
    });
    history.push(ROUTES.CREDIT_LINE_CONTRACT);
  };

  return useMutation(startApplication, { onSuccess });
}

export default useStartApplication;
