import { CognitoUser } from '@aws-amplify/auth';
import { Alert, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { theme } from 'context/ThemeProvider';
import { useAuthContext, UserAuthDetails } from 'context/AuthProvider';
import TextField from 'components/shared/TextField';
import { LocationState } from 'components/SignUpConfirmForm';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { normalizeEmail } from 'helpers/utils';
import { signInUser, getCognitoUserAuthDetails } from 'libs/signupHelpers';
interface FormValues {
  email: string;
  password: string;
}

export const COGNITO_AUTHENTICATION_ERRORS = [
  'UserNotFoundException',
  'NotAuthorizedException',
  'UsernameExistsException',
];

export const getValidRedirectPath = (path: string): string => {
  try {
    const url = new URL(path, window.location.origin);
    return `${url.pathname}${url.search}${url.hash}`;
  } catch {
    // If the path is not valid (URL is not in the same origin), return the home path
    return '/';
  }
};

const LogInForm: React.FC = () => {
  const history = useHistory();

  const { state: routerState } = useLocation<LocationState>();

  const { authChangeCallback } = useAuthContext();

  const onSubmit = (values: FormValues) => {
    loginUser(values);
  };

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: yup.object().shape({
        email: yup.string().required('Email is required'),
        password: yup.string().required('Password is required'),
      }),
      onSubmit,
      validateOnBlur: false,
    });

  /**
   * Initially, we were not lowercasing the email before sending it to Cognito.
   * This resulted in a bunch of customer complaints that they could not log in with their
   * email that they specified because they had the letter casing wrong (I insteaed of i, a instead of A).
   * This login flow attempts to use the provided input, but also tries the email lowercased.
   * New signups have the email lowercased, but we cannot assume that the old users are migrated to have
   * lowercase email usernames.
   */
  const logInUser = async ({ email, password }: FormValues) => {
    let normalizedEmail = normalizeEmail(email);

    const signInResult = await signInUser(normalizedEmail, password);

    if (!signInResult.cognitoUserConfirmed) {
      return {
        email: normalizedEmail,
        password,
      };
    }

    const authDetails = await getCognitoUserAuthDetails(
      signInResult.cognitoUser,
    );

    return {
      user: signInResult.cognitoUser,
      userDetails: authDetails,
      email: normalizedEmail,
      password,
    };
  };

  const onSuccess = ({
    user,
    userDetails,
    email,
    password,
  }: {
    user?: CognitoUser;
    userDetails?: UserAuthDetails;
    email: string;
    password: string;
  }) => {
    if (!user && !userDetails) {
      authChangeCallback(null, null, { email, password }, true);
      history.replace('/signup-confirm', {
        redirectPath: routerState?.redirectPath,
      });
      return;
    }

    if (user && userDetails) {
      authChangeCallback(user, userDetails, undefined, true);

      const redirectPath = routerState?.redirectPath
        ? getValidRedirectPath(routerState.redirectPath)
        : '/';

      history.replace(redirectPath);

      return;
    }

    if (rollbarEnabled) {
      rollbar.error('Unknown login state', {
        email,
      });
    }

    throw new Error('Unknown login state');
  };

  const {
    mutate: loginUser,
    error: serverError,
    isLoading,
  } = useMutation<
    {
      user?: CognitoUser;
      userDetails?: UserAuthDetails;
      email: string;
      password: string;
    },
    Error,
    FormValues
  >(logInUser, { onSuccess });

  return (
    <>
      {serverError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(5) }}
        >
          {serverError?.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} marginBottom={theme.spacing(3)}>
          <Grid item xs={12}>
            <TextField
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              error={!!(touched.email && errors.email)}
              errorText={errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="password"
              type="password"
              label="Password"
              autoComplete="current-password"
              error={!!(touched.password && errors.password)}
              errorText={errors.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Grid>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
        >
          Log In
        </LoadingButton>
      </form>
    </>
  );
};

export default LogInForm;
