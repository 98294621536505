import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Application, DigitalService } from 'types/schemas';
import {
  CheckoutPaymentMethods,
  PaymentMode,
  onConfirmPaymentMethod,
} from 'components/CheckoutPaymentMethods';
import { useAuthContext } from 'context/AuthProvider';
import { useClientsService } from 'hooks/useClientsService';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { USE_APPLICATIONS_QUERY_KEY } from 'hooks/queries/useGetApplications';
import { USE_ACCOUNTS_QUERY_KEY } from 'hooks/queries/useGetAccounts';
import { KovoError } from 'libs/KovoError';

export interface ApplicationSignupPaymentProps {
  application: Application;
  digitalService: DigitalService;
  /**
   * If true, the Apple Pay and Google Pay buttons will be disabled.
   * This is only used for unit testing.
   */
  walletsDisabled?: boolean;
}

const ApplicationSignupPayment: React.FC<ApplicationSignupPaymentProps> = ({
  application,
  digitalService,
  walletsDisabled = false,
}) => {
  const history = useHistory();
  const { email } = useAuthContext();
  const clientsService = useClientsService();

  const queryClient = useQueryClient();

  const completePayment = useCallback<onConfirmPaymentMethod>(
    async ({ token, walletType }) => {
      try {
        await clientsService.post(
          `/v1/applications/${application.sk}/complete`,
          {
            customerEmail: email,
            digitalServiceId: digitalService.id,
            paymentMethodToken: token,
          },
        );

        queryClient.invalidateQueries({
          queryKey: [USE_APPLICATIONS_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [USE_ACCOUNTS_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [USE_CURRENT_USER_QUERY_KEY],
        });

        history.replace('/');
      } catch (error) {
        if (error instanceof KovoError) {
          throw error;
        }

        throw new KovoError('Error completing payment')
          .addMetadata({
            walletType,
            loanProductId: application.loanProductId,
          })
          .setError(error);
      }
    },
    [
      email,
      application.sk,
      application.loanProductId,
      clientsService,
      queryClient,
      history,
      digitalService,
    ],
  );

  return (
    <CheckoutPaymentMethods
      amountInDollars={10}
      onConfirmPaymentMethod={completePayment}
      paymentMode={PaymentMode.PAYMENT}
      walletsDisabled={walletsDisabled}
    />
  );
};

export default ApplicationSignupPayment;
