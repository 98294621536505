import React from 'react';
import { useFormik } from 'formik';
import { Alert, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';

import { theme } from 'context/ThemeProvider';
import TextField from 'components/shared/TextField';
import { useUpdateEmailAttribute } from 'hooks/mutations/useUpdateEmailAttribute';

import { EMAIL_REGEX } from 'helpers/constants';

export interface Props {
  currentEmail: string;
}

interface FormValues {
  email: string;
}

const ChangeEmailForm: React.FC<Props> = ({ currentEmail }) => {
  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const { mutate, isLoading, error } = useUpdateEmailAttribute();

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: yup.object().shape({
        email: yup
          .string()
          .matches(EMAIL_REGEX, 'Invalid email')
          .required('Email is required'),
      }),
      onSubmit,
    });

  const errorMessage = error
    ? error.displayMessage || error.message + '. Please try again.'
    : undefined;

  return (
    <form onSubmit={handleSubmit} data-testid="change-email-form">
      {error ? (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(2) }}
        >
          {errorMessage}
        </Alert>
      ) : null}

      <Box>
        <Typography variant="footnote">Current email</Typography>
        <Typography sx={{ wordWrap: 'break-word' }}>
          <strong>{currentEmail}</strong>
        </Typography>
      </Box>

      <Box sx={{ marginBottom: theme.spacing(4), marginTop: theme.spacing(2) }}>
        <TextField
          name="email"
          type="email"
          label="New email"
          error={!!(touched.email && errors.email)}
          errorText={errors.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        />
      </Box>

      <LoadingButton
        variant="contained"
        type="submit"
        loading={isLoading}
        fullWidth
      >
        Confirm
      </LoadingButton>
    </form>
  );
};

export default ChangeEmailForm;
