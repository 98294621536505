import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { KovoError } from 'libs/KovoError';

interface FICOReason {
  reasonStatement: string;
  fullDescription: string;
  keepInMind: string;
}

interface CreditScoreRes {
  creditScore: CreditScore | null;
}

interface CreditScore {
  ficoScore: string;
  scoreDate: string;
  reasons: FICOReason[];
}

function useCreditScore() {
  const { identityId, username } = useContext(AuthContext);

  const getCreditScoreData = async (): Promise<CreditScoreRes> => {
    try {
      return API.get(
        'billing',
        '/billing/credit-score',
        amplifyRequestContext(identityId, username),
      );
    } catch (error) {
      throw new KovoError('Error fetching credit score data').setError(error);
    }
  };

  return useQuery<CreditScoreRes, KovoError>(
    ['credit-score'],
    () => getCreditScoreData(),
    { refetchOnWindowFocus: false },
  );
}

export default useCreditScore;
