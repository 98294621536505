import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import { AuthContext } from 'context/AuthProvider';
import { KovoError } from 'libs/KovoError';

import { amplifyRequestContext } from 'helpers/amplify-request-context';

export type Review = {
  userId: string;
  sk: string;
  ratingType: 'binary' | 'range';
  rating: number;
  text?: string;
  createdAt: string;
  updatedAt: string;
  type: 'in_app' | 'trust_pilot';
  platform?: 'ios' | 'android' | 'web';
};

export type GetReviewsResponse = {
  reviews: Review[];
};

function useGetReviews() {
  const { identityId, username } = useContext(AuthContext);

  const getReviews = async () => {
    try {
      const res = await API.get('clientsService', '/v1/reviews', {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      });

      return res;
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Failed to fetch reviews', { error });
    }
  };

  return useQuery<GetReviewsResponse>('reviews', getReviews);
}

export default useGetReviews;
