import { Box, CircularProgress } from '@mui/material';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {}

/**
 * The amount of time to wait before logging a rollbar error
 */
const ERROR_TIMEOUT = 1000 * 30;

/**
 * PageLoader is a component that is displayed when a page is loading.
 * It will display a loading spinner and log an error if the page takes too long to load.
 */
const PageLoader: React.FC<Props> = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (rollbarEnabled) {
        rollbar.warn(`Page took too long to load: ${pathname}`);
      }
    }, ERROR_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [pathname]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
      }}
    >
      <CircularProgress data-testid="page-loader" />
    </Box>
  );
};

export default PageLoader;
