import React, { useContext, useEffect } from 'react';
import { Card, CardContent, Typography, Link } from '@mui/material';

import Page from 'components/Page';
import useApplicationStatus from 'hooks/useApplicationStatus';
import PageLoader from 'components/PageLoader';
import { AuthContext } from 'context/AuthProvider';
import { useGlobalRedirectContext } from 'context/GlobalRedirectContext';

import ChangeEmailConfirmationForm from 'components/ChangeEmailConfirmationForm';
import { ROUTES } from 'components/Routes';
import { theme } from 'context/ThemeProvider';
import { useHistory } from 'react-router-dom';

const SettingsUpdateEmailPage: React.FC = () => {
  const { email, pendingEmail } = useContext(AuthContext);
  const { isLoading } = useApplicationStatus();
  const history = useHistory<{
    oneTimeRedirect?: boolean;
  }>();

  const globalRedirectContext = useGlobalRedirectContext();

  /**
   * If this logic of updating state is put into AuthRoute right before the <Redirect> component
   * is rendered, React complains about updating another component while one is already rendering.
   * Therefore, use that <Redirect> component to pass a bit of state to this page and use a one-time
   * useEffect instead to mark that we did this one time redirect and we should not do it again until
   * the user does a full page refresh.
   */
  useEffect(() => {
    if (history.location.state && history.location.state.oneTimeRedirect) {
      globalRedirectContext.setKey('updateEmailConfirmation', true);
      // clear the state of the router without a component rerender
      window.history.replaceState({}, '');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading || !email) {
    return <PageLoader />;
  }

  if (!pendingEmail) {
    return (
      <Page>
        <Card elevation={0}>
          <CardContent>
            <Typography>
              Please update your email before confirming. Update your email{' '}
              <Link href={ROUTES.SETTINGS_UPDATE_EMAIL}>here</Link>
            </Typography>
          </CardContent>
        </Card>
      </Page>
    );
  }

  return (
    <Page>
      <Typography variant="h2" sx={{ marginTop: theme.spacing(2) }}>
        Verify your new email
      </Typography>
      <Typography sx={{ marginTop: theme.spacing(2) }}>
        Check your email for a verification code.
      </Typography>
      <Card elevation={0} sx={{ marginTop: theme.spacing(2) }}>
        <CardContent>
          <ChangeEmailConfirmationForm
            currentEmail={email}
            newEmail={pendingEmail}
          />
        </CardContent>
      </Card>
    </Page>
  );
};

export default SettingsUpdateEmailPage;
