import { useAuthContext } from 'context/AuthProvider';
import { normalizeEmail } from 'helpers/utils';
import {
  KovoUnauthenticatedError,
  KovoEmailVerificationError,
  KovoError,
  KovoUserCreationError,
} from 'libs/KovoError';
import { useMutation } from 'react-query';
import { useClientsService } from './useClientsService';
import { useHandlePostUserSignup } from './useHandlePostUserSignup';
import { confirmUserEmail } from 'libs/signupHelpers/confirmUserEmail';
import { signInUser } from 'libs/signupHelpers/signInUser';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'components/Routes';

export interface ConfirmSignupParams {
  code: string;
}

const useConfirmUserSignup = () => {
  const { preConfirmationState } = useAuthContext();

  const { mutateAsync: handlePostUserSignup } = useHandlePostUserSignup();
  const clientsService = useClientsService();
  const history = useHistory();

  const confirmSignUp = async ({ code }: ConfirmSignupParams) => {
    try {
      if (!preConfirmationState) {
        throw new KovoError('User not in pre-confirmation state');
      }

      const email = normalizeEmail(preConfirmationState.email);

      await confirmUserEmail(email, code);

      const result = await signInUser(email, preConfirmationState.password);

      if (!result.cognitoUserConfirmed) {
        throw new KovoError('Error confirming user signup');
      }

      await handlePostUserSignup({
        cognitoUser: result.cognitoUser,
        clientsService,
      });
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error confirming user signup', { error });
    }
  };

  const res = useMutation<
    void,
    | KovoError
    | KovoUnauthenticatedError
    | KovoEmailVerificationError
    | KovoUserCreationError,
    ConfirmSignupParams
  >({
    mutationFn: confirmSignUp,
    mutationKey: 'confirmSignUp',
    onError(error) {
      if (error instanceof KovoUserCreationError) {
        history.push(ROUTES.SIGNUP_PENDING_ROUTE);
      }
    },
  });

  return res;
};

export default useConfirmUserSignup;
