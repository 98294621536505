// Wrappers around Impact script
import { API } from 'aws-amplify';
import { KovoError } from 'libs/KovoError';
// import { createHash } from 'crypto';

import { amplifyRequestContext } from './amplify-request-context';

declare global {
  interface Window {
    ire: any;
    uetq: any[];
  }
}

export const sha1 = async (customerEmail: string) => {
  const utf8 = new TextEncoder().encode(customerEmail);
  const hashBuffer = await crypto.subtle.digest('SHA-1', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
};

interface IdentifyOptions {
  customerId: string;
  customerEmail: string;
}

// customerId:  Customer ID  or  empty string if unknown.
// customerEmail:  SHA1 hash of customer’s email or  empty string if unknown.
export const identify = async (
  { customerId, customerEmail }: IdentifyOptions = {
    customerId: '',
    customerEmail: '',
  },
) => {
  if (!('ire' in window)) {
    return;
  }
  const customerEmailSHA1 =
    customerEmail === '' ? '' : await sha1(customerEmail);

  window.ire('identify', { customerId, customerEmail: customerEmailSHA1 });
};

// Action Tracker Parameters
//
// Values shown in red should be set dynamically. For each item in the cart, add an element to the array (the code snippet above shows an example of two items):
// orderId: (Required) Unique trackable ID for the sale. (AlphaNumeric 64)
// customerId: (Required) Unique customer identifier your platform assigns to customer accounts. Do not use personally identifiable data for this field. (AlphaNumeric 50)
// customerEmail: (Required) SHA1 hash of customer’s email. (String 64)
// customerStatus: (Required) Use values of “New” or “Returning”. This value can be used to alter payouts. (AlphaNumeric 40)
// currencyCode: (Required) ISO 4217 currency code (e.g. USD). Ok to omit if all amounts are USD.  (String 3)
// orderPromoCode: (Required) Coupon / Promo code (Pass an empty string if there is no promo code for the order). If more than one is used, only one can be reported to us in this field. This value can be used to alter payouts. (String 64)
// orderDiscount: (Required) Discount amount on the order. This amount will be subtracted from the sum of the line item subtotals and applied proportionally to each item. (Decimal 8,2)
// For each unique SKU in cart (code snippet shows 2 items):
// subTotal: (Required) Subtotal amount of SKU-set (quantity * price), pre-tax, pre-shipping, and pre-discount. (Decimal 12,2)
// category: (Required) Item category identifier. This value can be used to alter payouts.  (String 64)
// sku: (Required) Item specific identifier. (AlphaNumeric 40)
// quantity: (Required) Quantity of the line item. (Integer)

export const trackUpfrontPayment = ({
  customerId,
  customerEmail,
}: IdentifyOptions) => {
  if (!('ire' in window)) {
    return;
  }
  const customerEmailSHA1 = sha1(customerEmail);

  window.ire(
    'trackConversion',
    22544,
    {
      // orderId is the same as customerId since our customers will have 1 purchase only
      orderId: customerId,
      customerId: customerId,
      customerEmail: customerEmailSHA1,
      customerStatus: 'New',
      currencyCode: 'USD',
      orderPromoCode: '',
      orderDiscount: 0,
      items: [
        {
          subTotal: 240.0,
          category: 'UpfrontPurchase',
          sku: 'Upfront240',
          quantity: 1,
        },
      ],
    },
    { verifySiteDefinitionMatch: true },
  );
};

export const trackInitialPayment = async ({
  customerId,
  customerEmail,
}: IdentifyOptions) => {
  try {
    if (!('ire' in window)) {
      return;
    }
    const customerEmailSHA1 = sha1(customerEmail);

    const payload = {
      orderId: customerId,
      customerId: customerId,
      customerEmail: customerEmailSHA1,
      customerStatus: 'New',
      currencyCode: 'USD',
      orderPromoCode: '',
      orderDiscount: 0,
      items: [
        {
          subTotal: 10.0,
          category: 'InitialPayment',
          sku: 'Payment10',
          quantity: 1,
        },
      ],
    };

    window.ire(
      'trackConversion',
      22542,
      {
        orderId: customerId,
        customerId: customerId,
        customerEmail: customerEmailSHA1,
        customerStatus: 'New',
        currencyCode: 'USD',
        orderPromoCode: '',
        orderDiscount: 0,
        items: [
          {
            subTotal: 10.0,
            category: 'InitialPayment',
            sku: 'Payment10',
            quantity: 1,
          },
        ],
      },
      { verifySiteDefinitionMatch: true },
    );

    // Log event to server
    await API.post('billing', '/billing/impact_events', {
      body: {
        eventType: 'initial_installment_payment',
        orderId: payload.orderId,
        customerId: payload.customerId,
        customerEmail: payload.customerEmail,
        payload,
      },
      // customer id is user pool user id
      ...amplifyRequestContext(customerId, customerId),
    });
  } catch (error) {
    if (error instanceof KovoError) {
      throw error;
    }

    throw new KovoError('Error logging initial payment event', {
      error,
    });
  }
};
