import { useFormik } from 'formik';
import { Alert, DialogContent, DialogTitle, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';

import { theme } from 'context/ThemeProvider';
import TextField from 'components/shared/TextField';

import { ZIP_CODE_REGEX } from 'helpers/constants';
import { ADDRESS_FORM_FIELDS } from 'helpers/addressFormUtils';
import {
  AddressData,
  InvalidAddressDialogContent,
} from 'components/ApplyForm/InvalidAddressDialog';
import usePatchUser from 'hooks/mutations/usePatchUser';
import { RecommendedAddressDialogContent } from 'components/ApplyForm/RecommendedAddressDialog/RecommendedAddressDialog';
import { useRef } from 'react';

export interface Props {
  onSuccess?: () => void;
}

interface FormValues {
  address: string;
  address2: string;
  state: string;
  city: string;
  zipcode: string;
}

const ChangeAddressForm: React.FC<Props> = ({ onSuccess }) => {
  const {
    mutate: patchUser,
    isLoading,
    reset: resetPatch,
    error: patchUserError,
  } = usePatchUser({ onSuccess });

  const addressInputRef = useRef<HTMLInputElement>(null);

  const onSubmit = (values: FormValues) => {
    patchUser(values);
  };

  const handleAddressSelect = ({
    address,
    address2,
    city,
    state,
    zipcode,
  }: Partial<AddressData>) => {
    setFieldValue('address', address || '');
    setFieldValue('address2', address2 || '');
    setFieldValue('city', city || '');
    setFieldValue('state', state || '');
    setFieldValue('zipcode', zipcode || '');
  };

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      address: '',
      address2: '',
      state: '',
      city: '',
      zipcode: '',
    },
    validationSchema: yup.object().shape({
      address: yup.string().required('Street address is required'),
      state: yup.string().required('State is required'),
      city: yup.string().required('City is required'),
      zipcode: yup
        .string()
        .required('Zip code is required')
        .test('zipcode', 'Invalid zip code', (value = '') =>
          ZIP_CODE_REGEX.test(value),
        ),
    }),
    onSubmit,
    validateOnBlur: false,
  });

  const alertComponent = () => {
    if (!patchUserError) return null;

    if (
      patchUserError.metadata.missingComponentTypes ||
      patchUserError.metadata.formattedAddress
    ) {
      return null;
    }

    const errorMessage = patchUserError.displayMessage;

    return (
      <Alert severity="error" icon={false} sx={{ marginTop: theme.spacing(5) }}>
        {errorMessage}. Please try again.
      </Alert>
    );
  };

  const content = () => {
    if (patchUserError?.metadata.missingComponentTypes) {
      return (
        <InvalidAddressDialogContent
          address={values.address}
          address2={values.address2}
          city={values.city}
          state={values.state}
          zipcode={values.zipcode}
          missingComponents={patchUserError.metadata.missingComponentTypes}
          onConfirm={resetPatch}
        />
      );
    }

    if (patchUserError?.metadata.formattedAddress) {
      return (
        <RecommendedAddressDialogContent
          originalAddress={{
            address: values.address,
            address2: values.address2,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
          }}
          formattedAddress={patchUserError.metadata.formattedAddress}
          onConfirm={(addressData) => {
            handleAddressSelect(addressData);
            resetPatch();
            handleSubmit();
          }}
        />
      );
    }
    return (
      <>
        <DialogTitle variant="h4">Enter your new address</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={{ xs: 2, sm: 3 }}
            marginBottom={theme.spacing(3)}
          >
            {ADDRESS_FORM_FIELDS.map(
              ({ name, label, Component = TextField, helperText }) => {
                const props = {
                  name,
                  label,
                  value: (values as any)[name],
                  error: !!((touched as any)[name] && (errors as any)[name]),
                  errorText: (errors as any)[name],
                  helperText,
                  setFieldValue,
                  onAddressSelect: handleAddressSelect,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  fullWidth: true,

                  ...(name === 'zipcode' && {
                    type: 'tel' as 'tel',
                    pattern: '[0-9-]*',
                    maxLength: 5,
                  }),

                  ...(name === 'address' && {
                    addressInputRef,
                  }),
                };

                return (
                  <Grid item xs={12} key={name}>
                    <Component {...props} />
                  </Grid>
                );
              },
            )}
          </Grid>

          <LoadingButton
            variant="contained"
            type="submit"
            loading={isLoading}
            fullWidth
          >
            Confirm
          </LoadingButton>
        </DialogContent>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      {alertComponent()}
      {content()}
    </form>
  );
};

export default ChangeAddressForm;
