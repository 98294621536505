import { useContext } from 'react';
import { API } from 'aws-amplify';
import { useQuery } from 'react-query';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { ReferralCode } from 'types/ReferralCode';
import { KovoError } from 'libs/KovoError';

export interface FetchReferralCodeResponse {
  status: string;
  referralCode: ReferralCode | null;
}

export const useReferralCode = () => {
  const { identityId, username } = useContext(AuthContext);

  const fetchReferralCode = async (): Promise<FetchReferralCodeResponse> => {
    try {
      return API.get(
        'billing',
        '/billing/referral-code',
        amplifyRequestContext(identityId, username),
      );
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Failed to fetch referral code', { error });
    }
  };

  return useQuery<FetchReferralCodeResponse, KovoError, ReferralCode | null>(
    ['referralCode'],
    () => fetchReferralCode(),
    {
      select: (data) => data.referralCode,
    },
  );
};
