import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  Skeleton,
} from '@mui/material';
import { theme } from 'context/ThemeProvider';

import Checkmark from 'assets/img/icons/checkmark-circle-green.svg';
import InactiveIcon from 'assets/img/icons/x-circle-grey.svg';
import IdMonitoringAlertCountCard from 'components/IdMonitoringAlertCountCard';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import { isInstallmentAccountValidForIdMonitoring } from 'helpers/accounts';
import { useAlertsSummary } from 'hooks/queries/useIdMonitoringAlerts';
import { useState } from 'react';
import IdMonitoringAlertDetailsDialog from 'components/IdMonitoringAlertDetailsDialog';

export enum AlertLevel {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

type IdMonitoringFeatureCardProps = {
  isFeatureEnabled: boolean;
};

const IdMonitoringFeatureCard = ({
  isFeatureEnabled,
}: IdMonitoringFeatureCardProps) => {
  const [selectedAlertLevelView, setSelectedAlertLevelView] =
    useState<AlertLevel | null>(null);
  const { data: alertSummary, isLoading: isLoadingAlertSummary } =
    useAlertsSummary();
  const { data: accountsData, isLoading: isLoadingAccountsData } =
    useGetAccounts();

  const installmentAccount = accountsData?.installmentAccount;
  const isIdMonitoringActive =
    isInstallmentAccountValidForIdMonitoring(installmentAccount);

  if (isLoadingAccountsData) {
    return <Skeleton data-testid="skeleton" variant="rounded" height={342} />;
  }

  const statusText = () => {
    if (!isIdMonitoringActive) {
      return 'Inactive';
    }
    if (!isFeatureEnabled) {
      return 'Coming soon';
    }
    return 'Active';
  };

  const statusIcon = () => {
    if (!isIdMonitoringActive) {
      return InactiveIcon;
    }
    return Checkmark;
  };

  return (
    <>
      {selectedAlertLevelView && (
        <IdMonitoringAlertDetailsDialog
          open={true}
          onClose={() => setSelectedAlertLevelView(null)}
          alertLevel={selectedAlertLevelView}
        />
      )}
      <Card elevation={0} data-testid="id-monitoring-card">
        <CardContent>
          <Typography variant="h5">ID MONITORING</Typography>

          <Divider sx={{ margin: theme.spacing(2, 0, 3) }} />

          <Box
            display="flex"
            alignItems="center"
            marginBottom={theme.spacing(3)}
          >
            <Box
              component="img"
              src={statusIcon()}
              alt=""
              marginRight={theme.spacing(1.5)}
            />
            <Typography>{statusText()}</Typography>
          </Box>

          <Box display="flex" gap={theme.spacing(1)}>
            <IdMonitoringAlertCountCard
              isLoading={isLoadingAlertSummary}
              label="High risk alerts"
              count={isFeatureEnabled ? alertSummary?.highSeverityCount : 0}
              onViewAlertsClick={() =>
                setSelectedAlertLevelView(AlertLevel.HIGH)
              }
            />
            <IdMonitoringAlertCountCard
              isLoading={isLoadingAlertSummary}
              label="Medium risk alerts"
              count={isFeatureEnabled ? alertSummary?.mediumSeverityCount : 0}
              onViewAlertsClick={() =>
                setSelectedAlertLevelView(AlertLevel.MEDIUM)
              }
            />
            <IdMonitoringAlertCountCard
              isLoading={isLoadingAlertSummary}
              label="Low risk alerts"
              count={isFeatureEnabled ? alertSummary?.lowSeverityCount : 0}
              onViewAlertsClick={() =>
                setSelectedAlertLevelView(AlertLevel.LOW)
              }
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default IdMonitoringFeatureCard;
