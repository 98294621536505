import Cookies from 'js-cookie';

const LAST_UPDATE_COOKIE = 'kovo_last_update';
const ONE_DAY_MS = 24 * 60 * 60 * 1000;

export const setLastWebAppUpdateCookie = () => {
  Cookies.set(LAST_UPDATE_COOKIE, new Date().getTime().toString(), {
    expires: 365,
  });
};

export const getLastWebAppUpdateCookie = () => {
  return Cookies.get(LAST_UPDATE_COOKIE);
};

/**
 * Checks if the web app needs to be refreshed based on the last update time.
 * If the last update was more than a day ago or if there's no record of the last update,
 * this function will trigger a hard refresh of the page.
 * This helps ensure that users are always running the latest version of the web app.
 */
export function checkLastUpdateAndRefreshIfNeeded() {
  const lastUpdate = getLastWebAppUpdateCookie();
  const now = new Date().getTime();

  if (!lastUpdate || now - parseInt(lastUpdate, 10) > ONE_DAY_MS) {
    // Perform a hard refresh if needed
    window.location.reload();
  }
}
