import { useQuery } from 'react-query';
import { DigitalServiceId } from 'types/schemas';
import { ClientsService } from 'libs/ClientsService';
import { useClientsService } from 'hooks/useClientsService';
import { Purchase } from 'types/schemas/purchase';
import { KovoError } from 'libs/KovoError';

type GetPurchasesRes = {
  purchases: Purchase[];
};

type PurchasesFilters = {
  status?: Purchase['status'];
  digitalServiceId?: DigitalServiceId;
};

const getPurchases = async (
  clientsService: ClientsService,
  filters: PurchasesFilters = {},
) => {
  try {
    let query = new URLSearchParams(filters).toString();

    const res = await clientsService.get<GetPurchasesRes>(
      `/v2/purchases${query ? `?${query}` : ''}`,
    );

    return res;
  } catch (error) {
    if (error instanceof KovoError) {
      throw error;
    }

    throw new KovoError(
      'Error getting purchases. Please refresh to try again.',
    ).exposeMessage();
  }
};

function useGetPurchases(filters: PurchasesFilters = {}) {
  const clientsService = useClientsService();

  return useQuery<GetPurchasesRes>(['purchases', filters], () =>
    getPurchases(clientsService, filters),
  );
}

export default useGetPurchases;
