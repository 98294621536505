import { API } from 'aws-amplify';
import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import {
  ClientTransaction,
  TransactionTriggers,
  getTransactions,
} from 'hooks/queries/useGetTransactions';
import { KovoError } from 'libs/KovoError';

type CreateTransactionRequestBody = Pick<
  ClientTransaction,
  'amount' | 'adjustment' | 'shouldCapture'
> &
  Partial<
    Pick<
      ClientTransaction,
      'effectiveAt' | 'paymentMethodId' | 'status' | 'metadata'
    >
  > & {
    trigger: TransactionTriggers;
  };

async function pollTransaction(
  transactionId: string,
  accountId: string,
  userId: string,
  username: string,
  attempts = 0,
  waitTimeout = 3000,
): Promise<ClientTransaction> {
  const { transactions } = await getTransactions(accountId, userId, username);

  const transaction = transactions.find((t) => t.id === transactionId);

  if (!transaction) {
    throw new Error('Transaction not found when polling for status updates');
  }

  if (attempts > 20) {
    throw new KovoError('Transaction took too long to process').addMetadata({
      transactionId,
      accountId,
      userId,
    });
  }

  if (transaction.status === 'processing') {
    await new Promise((resolve) => setTimeout(resolve, waitTimeout));

    return pollTransaction(
      transactionId,
      accountId,
      userId,
      username,
      attempts + 1,
    );
  }

  return transaction;
}

export const createTransaction = async ({
  accountId,
  amount,
  trigger,
  adjustment,
  shouldCapture,
  effectiveAt,
  paymentMethodId,
  status,
  metadata,
  userId,
  username,
}: {
  accountId: string;
  userId: string;
  username: string;
} & CreateTransactionRequestBody) => {
  const res: ClientTransaction = await API.post(
    'clientsService',
    `/v1/accounts/${accountId}/transactions`,
    {
      body: {
        amount,
        trigger,
        adjustment,
        shouldCapture,
        effectiveAt,
        paymentMethodId,
        status,
        metadata,
      },
      headers: {
        ...amplifyRequestContext(userId, username, {
          'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
          'x-user-id': userId,
          'content-type': 'application/json',
        }).headers,
      },
    },
  );

  const transaction = await pollTransaction(
    res.id,
    res.accountId,
    userId,
    username,
  );

  if (transaction.integrationResponseCode !== '10000') {
    const errorMetadata = {
      transactionId: transaction.id,
      accountId,
      integrationResponseCode: transaction.integrationResponseCode,
      integrationResponseDescription:
        transaction.integrationResponseDescription,
    };

    if (transaction.responseSummary) {
      throw new KovoError(transaction.responseSummary)
        .setLogLevel('info')
        .exposeMessage()
        .addMetadata(errorMetadata);
    }

    throw new KovoError('Error creating transaction').addMetadata(
      errorMetadata,
    );
  }

  return transaction;
};

function useCreateTransaction(
  options?: UseMutationOptions<void, AxiosError, any, any>,
) {
  const onSuccess = () => {
    if (options?.onSuccess) {
      options.onSuccess(undefined, undefined, undefined);
    }
  };

  return useMutation(createTransaction, { onSuccess });
}

export default useCreateTransaction;
