import { FC, useCallback } from 'react';
import { CheckoutPaymentMethods } from '../CheckoutPaymentMethods';
import {
  PaymentMode,
  onConfirmPaymentMethod,
} from '../CheckoutPaymentMethods/types';

import { useClientsService } from 'hooks/useClientsService';
import { Elements, StripeProvider } from 'react-stripe-elements';
import StripeInstallment from 'components/StripeInstallment';
import config from 'config';
import { ProductTypes } from 'types/schemas';
import { useProductConfig } from 'hooks/useProductConfig';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { USE_ACCOUNTS_QUERY_KEY } from 'hooks/queries/useGetAccounts';
import { KovoError } from 'libs/KovoError';

export interface UpdatePaymentMethodProps {
  billingProvider: 'checkout' | 'stripe';
  productType: ProductTypes;
  /**
   * If true, the Apple Pay and Google Pay buttons will be disabled.
   */
  walletsDisabled?: boolean;
}

const UpdatePaymentMethod: FC<UpdatePaymentMethodProps> = ({
  billingProvider,
  productType,
  walletsDisabled = false,
}) => {
  const clientsService = useClientsService();
  const productConfig = useProductConfig(productType);
  const history = useHistory();
  const queryClient = useQueryClient();
  const isInMobileAppWebview = window.ReactNativeWebView !== undefined;

  const onConfirmPaymentMethod = useCallback<onConfirmPaymentMethod>(
    async ({ token, walletType, values, last4, scheme }) => {
      try {
        console.log(
          'onConfirmPaymentMethod',
          token,
          walletType,
          values,
          last4,
          scheme,
        );
        await clientsService.post('/v1/payment_methods', {
          integrationId: 'checkout',
          postalCode: values?.zipcode,
          integrationSourceToken: token,
          default: true,
          walletType,
          last4,
          scheme,
        });

        queryClient.invalidateQueries({
          queryKey: [USE_ACCOUNTS_QUERY_KEY],
        });
        history.push(productConfig.infoRoute);
      } catch (error) {
        if (error instanceof KovoError) {
          throw error;
        }

        throw new KovoError('Error updating payment method')
          .exposeMessage()
          .setError(error);
      }
    },
    [clientsService, history, productConfig.infoRoute, queryClient],
  );

  if (billingProvider !== 'checkout') {
    return (
      <StripeProvider apiKey={config.STRIPE_KEY}>
        <Elements>
          <StripeInstallment isUpdating />
        </Elements>
      </StripeProvider>
    );
  }

  return (
    <CheckoutPaymentMethods
      amountInDollars={0}
      paymentMode={PaymentMode.UPDATE_METHOD}
      onConfirmPaymentMethod={onConfirmPaymentMethod}
      walletsDisabled={walletsDisabled}
      googlePayDisabled={isInMobileAppWebview}
    />
  );
};

export default UpdatePaymentMethod;
