import { Auth } from 'aws-amplify';
import { KovoError } from 'libs/KovoError';

import {
  PENDING_EMAIL_ATTRIBUTE_KEY,
  PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY,
} from './types';

/**
 * Use a confirmation code to confirm an email update
 */
export const confirmEmailAttribute = async (code: string) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();

    await Auth.verifyUserAttributeSubmit(currentUser, 'email', code);
    await Auth.deleteUserAttributes(currentUser, [
      PENDING_EMAIL_ATTRIBUTE_KEY,
      PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY,
    ]);

    const afterUpdate = await Auth.currentAuthenticatedUser();
    return afterUpdate;
  } catch (error) {
    throw new KovoError('Error confirming email', {
      error,
    }).setDefaultDisplayMessage('Error confirming email. Please try again.');
  }
};
