import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { KovoError } from 'libs/KovoError';

function useContractDownloadUrl(identityId?: string, username?: string) {
  const getDownloadUrl = async (identityId?: string, username?: string) => {
    try {
      if (!identityId || !username) return;

      const res = await API.post(
        'installments',
        '/installments/downloadUrl',
        amplifyRequestContext(identityId, username),
      );

      if (res.error) {
        throw res.error;
      }

      return res.downloadUrl;
    } catch (error) {
      throw new KovoError('Error generating contract link').setError(error);
    }
  };

  return useQuery<string>(
    ['contractDownloadUrl', identityId, username],
    () => getDownloadUrl(identityId, username),
    { refetchOnWindowFocus: false },
  );
}

export default useContractDownloadUrl;
