import { AxiosError } from 'axios';
import { KovoError } from './KovoError';

export const parsePaymentError = (error: Error | string | object) => {
  if (!error) {
    return null;
  }

  if (error === 'Card form invalid') {
    return 'Card information invalid';
  }

  if (typeof error === 'string') {
    return error;
  }

  /**
   * For KovoError's we want to show the user the display message.
   */
  if (error instanceof KovoError) {
    return error.displayMessage;
  }

  const isTransactionError =
    ((
      error: unknown,
    ): error is AxiosError<{
      data?: { errorCode: string; userMessage: string; status: string };
    }> => error instanceof AxiosError)(error) &&
    error.response?.data?.data?.userMessage;

  if (isTransactionError) {
    return error.response?.data?.data?.userMessage;
  }

  return 'Something went wrong. Please try again.';
};
