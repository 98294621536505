import { API } from 'aws-amplify';
import Rollbar, { LogArgument } from 'rollbar';
import { KovoError } from './KovoError';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import config from 'config';
import posthog from 'posthog-js';

interface LogOnboardingEventParameters {
  eventName: string;
  email: string;
}

export function logOnboardingEvent({
  eventName,
  email,
}: LogOnboardingEventParameters) {
  try {
    API.post('installments', `/installments/logger`, {
      body: {
        eventType: 'onboarding',
        eventName: eventName,
        email: email,
      },
    });
  } catch (err: any) {}

  return null;
}

type LogLevelOptions = LogLevel | 'none';

interface LoggerOptions {
  logLevel: LogLevelOptions;
  rollbarOptions: {
    rollbar: Rollbar;
    rollbarEnabled: boolean;
  };
}

export const LogLevels = ['debug', 'info', 'warn', 'error'] as const;

export type LogLevel = typeof LogLevels[number];

export class Logger {
  logLevels = LogLevels;

  constructor(private options: LoggerOptions) {}

  private log(level: LogLevel, logArguments: LogArgument[]) {
    const featureFlagLogLevel = posthog.getFeatureFlag('LOG_LEVEL');

    const maxLogLevel = this.isValidLogLevel(featureFlagLogLevel)
      ? featureFlagLogLevel
      : this.options.logLevel;

    if (maxLogLevel === 'none') {
      return;
    }

    const logLevelIndex = this.logLevels.indexOf(level);
    const maxLogLevelIndex = this.logLevels.indexOf(maxLogLevel);

    if (logLevelIndex < maxLogLevelIndex) {
      return;
    }

    const { rollbar, rollbarEnabled } = this.options.rollbarOptions;

    if (rollbarEnabled) {
      rollbar[level](...logArguments.filter(Boolean));
    } else {
      console[level](...logArguments);
    }
  }

  debug(message: string, metadata?: Record<string, unknown>) {
    this.log('debug', [message, metadata]);
  }

  info(message: string, metadata?: Record<string, unknown>) {
    this.log('info', [message, metadata]);
  }

  warn(message: string, metadata?: Record<string, unknown>) {
    this.log('warn', [message, metadata]);
  }

  error(
    error: unknown,
    options: Record<string, unknown> & { prefix?: string } = {},
  ) {
    const message =
      error instanceof Error ? error.message : 'An unknown error occurred';
    const isKovoError = error instanceof KovoError;

    const { prefix, ...metadata } = options;
    const errorDetails = isKovoError ? error.details : {};

    const logMetadata = {
      ...metadata,
      ...errorDetails,
    };

    const prefixedMessage = [prefix, message].filter(Boolean).join(': ');

    const logArguments = [
      prefixedMessage,
      error as LogArgument,
      Object.keys(logMetadata).length > 0 ? logMetadata : undefined,
    ].filter(Boolean);

    this.log(isKovoError ? error.logLevel : 'error', logArguments);
  }

  get logLevel() {
    return this.options.logLevel;
  }

  isValidLogLevel(
    logLevel: string | boolean | undefined,
  ): logLevel is LogLevel {
    return LogLevels.includes(logLevel as LogLevel);
  }
}

/**
 * The instance of the logger that is used throughout the application.
 * The log level is set via the VITE_LOG_LEVEL environment variable
 * or using the default log level from the config, which is env specific.
 *
 * Something to consider in the future is controlling the log level via
 * a feature flag so it's possible to turn on debug logging in deployed
 * environments without having to change the code.
 */
export const logger = new Logger({
  logLevel: import.meta.env.VITE_LOG_LEVEL ?? config.LOG_LEVEL,
  rollbarOptions: {
    rollbar,
    rollbarEnabled,
  },
});
