import { getCurrentUser } from 'hooks/queries/useCurrentUser';
import { ClientsService } from 'libs/ClientsService';
import { KovoError } from 'libs/KovoError';
import getSignupData from './getSignupData';

export interface CreateUserRecordOptions {
  email: string;
  userId: string;
  userPoolUserId: string;
}

/**
 * Creates a new user in the database.
 */
export const createUserRecord = async (
  clientsService: ClientsService,
  userDetails: CreateUserRecordOptions,
) => {
  try {
    const signupData = getSignupData();
    await clientsService
      .setHeaderOptions({
        userId: userDetails.userId,
        userPoolUserId: userDetails.userPoolUserId,
      })
      .post(`/v1/signup`, {
        email: userDetails.email,
        referralCodeId: signupData.referralCode,
        ...signupData,
      });

    const user = await getCurrentUser(
      clientsService.setHeaderOptions({
        userId: userDetails.userId,
        userPoolUserId: userDetails.userPoolUserId,
      }),
    );

    return user;
  } catch (error) {
    throw new KovoError('Failed to signup user', { error });
  }
};
