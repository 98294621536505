import { FC, useCallback, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import axios from 'axios';
import { Box } from '@mui/material';

import { loadScript } from 'helpers/load-script';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';

import './ApplePayButton.css';
import {
  PaymentMode,
  WalletComponentProps,
} from 'components/CheckoutPaymentMethods/types';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from 'context/AuthProvider';
import { InboundMobileAppMessage } from 'types';
import { sendMessageToMobileApp } from 'helpers/mobile-app';
import { logger } from 'libs/logger';

const CHECKOUT_PUBLIC_KEY = import.meta.env.VITE_CHECKOUT_PUBLIC_KEY!;
const CHECKOUT_DOMAIN = import.meta.env.VITE_CHECKOUT_DOMAIN!;

declare global {
  interface Window {
    ApplePaySession: ApplePaySession;
    session: ApplePaySession;
  }
}

const APPLE_PAY_VERSION = 14;

const userCanUseApplePay = () => {
  return (
    window.ApplePaySession &&
    ApplePaySession.canMakePayments() &&
    ApplePaySession.supportsVersion(APPLE_PAY_VERSION)
  );
};

const ApplePayButton: FC<WalletComponentProps> = ({
  onConfirmPaymentMethod,
  onLoad,
  disabled = false,
  paymentMode,
  amountInDollars,
  onError,
}: WalletComponentProps) => {
  const [isInitializing, setIsInitializing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSupportedPaymentMethod, setIsSupportedPaymentMethod] = useState<
    boolean | undefined
  >();
  const { identityId: userId, username } = useAuthContext();

  const [setupComplete, setSetupComplete] = useState(false);

  const isEnabled = !disabled;

  const onPaymentAuthorized = useCallback(
    (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {
      const onPaymentAuthorizedFunction = async () => {
        try {
          setIsProcessing(true);

          const payment = event.payment;
          const exchangeTokenRes = await axios.post<{
            token: string;
            scheme: string;
          }>(
            `${CHECKOUT_DOMAIN}/tokens`,
            {
              type: 'applepay',
              token_data: payment.token.paymentData,
            },
            {
              headers: {
                Authorization: CHECKOUT_PUBLIC_KEY,
                'Content-Type': 'application/json',
              },
            },
          );

          await onConfirmPaymentMethod({
            token: exchangeTokenRes.data.token,
            scheme: exchangeTokenRes.data.scheme,
            walletType: 'apple_pay',
          });

          if (window.session) {
            window.session.completePayment(ApplePaySession.STATUS_SUCCESS);
          }
        } catch (error) {
          if (window.session) {
            window.session.completePayment(ApplePaySession.STATUS_FAILURE);
          }

          if (error instanceof Error || typeof error === 'string') {
            onError(error);
          } else {
            onError(new Error('Something went wrong. Please try again'));
          }
        } finally {
          setIsProcessing(false);
        }
      };
      onPaymentAuthorizedFunction();
    },
    [onConfirmPaymentMethod, onError],
  );

  useEffect(() => {
    if (isSupportedPaymentMethod !== undefined) return;
    if (isInitializing) return;

    if (isEnabled === false) {
      setIsSupportedPaymentMethod(false);
      onLoad(false);
      return;
    }

    const load = async () => {
      try {
        setIsInitializing(true);

        await loadScript(
          'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js',
          {
            async: 'true',
            crossorigin: 'anonymous',
          },
        );
        const canMakePayments = userCanUseApplePay();

        setIsSupportedPaymentMethod(canMakePayments ?? false);
        onLoad(canMakePayments ?? false);
      } catch (error) {
        setIsSupportedPaymentMethod(false);
        onLoad(false);

        const typedError = error as Error;

        if (typedError.message?.includes('is not a supported version')) {
          return;
        }

        if (rollbarEnabled) {
          rollbar.error(`Error loading up ApplePay: ${typedError.message}`, {
            userId,
            error,
          });
        }
      } finally {
        setIsInitializing(false);
      }
    };

    load();
  }, [isInitializing, isSupportedPaymentMethod, onLoad, isEnabled, userId]);

  useEffect(() => {
    if (!isSupportedPaymentMethod || setupComplete) return;

    const setupApplePay = async () => {
      try {
        const canMakePayments = userCanUseApplePay();

        if (!canMakePayments) return;

        const recurringPaymentRequest: ApplePayJS.ApplePayRecurringPaymentRequest =
          {
            paymentDescription: 'Kovo Installment Plan',
            regularBilling: {
              amount: amountInDollars ? amountInDollars.toString() : '10.00',
              label: 'Monthly payment',
              recurringPaymentStartDate: new Date().toISOString(),
              recurringPaymentIntervalCount: 1,
              recurringPaymentIntervalUnit: 'month',
              paymentTiming: 'recurring',
            },
            managementURL: window.location.origin,
          };

        /**
         * The ApplePayPaymentRequest dictionary is used to initialize an Apple Pay payment request.
         * The payment request contains information about the amountInDollars of money the user is paying,
         * as well as which payment processing methods your website supports.
         * The user sees this information in the Apple Pay payment sheet.
         * https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest
         */
        const request: ApplePayJS.ApplePayPaymentRequest = {
          countryCode: 'US',
          currencyCode: 'USD',
          supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
          /**
           * This is a requirement for ApplePay.
           */
          merchantCapabilities: ['supports3DS'],
          total: {
            label: 'Kovo',
            amount: amountInDollars.toString(),
            type: 'final',
          },
          recurringPaymentRequest:
            paymentMode === PaymentMode.PAYMENT
              ? undefined
              : recurringPaymentRequest,
        };

        if (window.ReactNativeWebView) {
          // Send the payment request to the mobile app
          sendMessageToMobileApp({
            eventType: 'kovo.webapp.click.apple_pay',
            body: {
              ...request,
              applePayMerchantId: import.meta.env.VITE_APPLE_PAY_MERCHANT_ID,
            },
          });
          return;
        }

        const session = new ApplePaySession(APPLE_PAY_VERSION, request);

        /**
         * This event is fired when the payment sheet is displayed.
         * The merchant can now request the payment amountInDollars and line items
         * from the server and show them on the payment sheet.
         */
        session.onvalidatemerchant = async (validateMerchantEvent) => {
          try {
            const validation = await API.post(
              'billing',
              '/apple-pay/session/validate',
              {
                body: {
                  applePayValidationUrl: validateMerchantEvent.validationURL,
                  initiativeContext: `${window.location.hostname}`,
                  applePayMerchantId: import.meta.env
                    .VITE_APPLE_PAY_MERCHANT_ID,
                },
                ...amplifyRequestContext(userId, username),
              },
            );

            session.completeMerchantValidation(validation);
          } catch (error) {
            const typedError = error as Error;

            rollbarEnabled &&
              rollbar.error(
                `Error validating ApplePay session: ${typedError.message}`,
                {
                  userId,
                  error,
                },
              );
          }
        };

        /**
         * This event is fired when the user has authorized the Apple Pay payment
         * using Touch ID, Face ID, or passcode.
         */
        session.onpaymentauthorized = onPaymentAuthorized;

        window.session = session;

        window.session.begin();
      } catch (error) {
        const expectedErrors = ['Page already has an active payment session.'];
        if (error instanceof Error) {
          if (!expectedErrors.includes(error.message)) {
            logger.error(error);
          }
        }
      }
    };

    const element = document.getElementById('apple-pay-button');
    element?.addEventListener('click', setupApplePay, false);

    setSetupComplete(true);
  }, [
    setupComplete,
    userId,
    onConfirmPaymentMethod,
    isSupportedPaymentMethod,
    amountInDollars,
    paymentMode,
    onError,
    onPaymentAuthorized,
    username,
  ]);

  useEffect(() => {
    function handleMobileAppMessage(this: Window, event: MessageEvent<any>) {
      const message: InboundMobileAppMessage = event.data;

      switch (message.eventType) {
        case 'kovo.mobile_app.complete.apple_pay':
          onPaymentAuthorized(message.body);
          break;

        default:
          break;
      }
    }

    if (window.ReactNativeWebView) {
      window.addEventListener('message', handleMobileAppMessage);
    }

    return () => {
      if (window.ReactNativeWebView) {
        window.removeEventListener('message', handleMobileAppMessage);
      }
    };
  }, [onPaymentAuthorized]);

  if (isProcessing) {
    <LoadingButton
      variant="contained"
      loading
      fullWidth
      sx={{ height: '55px', borderRadius: '4px' }}
    />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {isProcessing && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            cursor: 'not-allowed',
            zIndex: 9,
          }}
        />
      )}

      <Box
        sx={{ lineHeight: 0 }}
        dangerouslySetInnerHTML={{
          __html:
            '<apple-pay-button id="apple-pay-button" buttonstyle="black" type="plain"></apple-pay-button>',
        }}
      />
    </Box>
  );
};

export default ApplePayButton;
