import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { AuthContext } from 'context/AuthProvider';
import { AlertLevel } from 'components/IdMonitoringFeatureCard/IdMonitoringFeatureCard';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { isFeatureFlagEnabled } from 'helpers/featureFlags';
import { KovoError } from 'libs/KovoError';

export interface AlertSummary {
  lowSeverityCount: number;
  mediumSeverityCount: number;
  highSeverityCount: number;
}

export interface AlertDetailsEntry {
  website: string;
  date: string;
  description: string;
}

export interface AlertList {
  alerts: AlertDetailsEntry[];
}

export const useAlertsSummary = () => {
  const { identityId, username } = useContext(AuthContext);

  const getAlertSummary = async (identityId: string) => {
    if (isFeatureFlagEnabled('FF_ID_MONITORING') === false) {
      return {
        lowSeverityCount: 0,
        mediumSeverityCount: 0,
        highSeverityCount: 0,
      };
    }

    const res: AlertSummary = await API.get(
      'clientsService',
      `/v1/id-monitoring/alerts/summary`,
      {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      },
    );

    return res;
  };

  return useQuery<AlertSummary>(
    ['alertSummary', identityId],
    () => getAlertSummary(identityId),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const useAlertsList = (alertLevel: AlertLevel | null) => {
  const { identityId, username } = useContext(AuthContext);

  const getAlertsList = async (
    identityId: string,
    alertLevel: AlertLevel | null,
  ) => {
    try {
      if (!alertLevel) {
        return {
          alerts: [],
        };
      }
      const res: AlertList = await API.get(
        'clientsService',
        `/v1/id-monitoring/alerts?alertLevel=${alertLevel}`,
        {
          headers: {
            ...amplifyRequestContext(identityId, username, {
              'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
              'x-user-id': identityId,
            }).headers,
          },
        },
      );

      return res;
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Failed to fetch alerts', { error });
    }
  };

  return useQuery<AlertList, KovoError>(
    ['alertList', identityId + alertLevel],
    () => getAlertsList(identityId, alertLevel),
    {
      refetchOnWindowFocus: false,
    },
  );
};
