import React, { useContext } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

import Page from 'components/Page';
import useApplicationStatus from 'hooks/useApplicationStatus';
import PageLoader from 'components/PageLoader';
import { AuthContext } from 'context/AuthProvider';

import ChangeEmailForm from 'components/ChangeEmailForm/ChangeEmailForm';
import { theme } from 'context/ThemeProvider';

const SettingsUpdateEmailPage: React.FC = () => {
  const { email } = useContext(AuthContext);
  const { isLoading } = useApplicationStatus();

  if (isLoading || !email) {
    return <PageLoader />;
  }

  return (
    <Page>
      <Typography variant="h2" sx={{ marginTop: theme.spacing(2) }}>
        Enter your new email
      </Typography>
      <Card elevation={0} sx={{ marginTop: theme.spacing(2) }}>
        <CardContent>
          <ChangeEmailForm currentEmail={email} />
        </CardContent>
      </Card>
    </Page>
  );
};

export default SettingsUpdateEmailPage;
