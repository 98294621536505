import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { KovoError } from 'libs/KovoError';

export type Metro2Info = {
  hasDerogatoryMark: boolean;
  hasDisputes: boolean;
  isAllAccountsActive: boolean;
};

function useGetMetro2Info() {
  const { identityId, username } = useContext(AuthContext);

  const getMetro2Status = async () => {
    try {
      const res = await API.get('clientsService', `/v1/metro2_records/info`, {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      });

      return res;
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error getting metro2 info', { error });
    }
  };

  return useQuery<Metro2Info, KovoError>('metro2Info', getMetro2Status, {
    retry: false,
    refetchOnWindowFocus: false,
  });
}

export default useGetMetro2Info;
