import { API } from 'aws-amplify';
import { useQuery, UseQueryOptions } from 'react-query';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { ApplicationStatusResponse } from 'context/Profile';
import useCurrentUser from './queries/useCurrentUser';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';
import { KovoError } from 'libs/KovoError';

function useApplicationStatus(
  options?: UseQueryOptions<ApplicationStatusResponse>,
) {
  const { data: user } = useCurrentUser();

  const getApplicationStatus = async () => {
    try {
      if (!user) {
        throw new Error('User not found');
      }

      const applicationStatus = await API.get(
        'installments',
        '/installments/application-status',
        amplifyRequestContext(user.userId, user.userPoolUserId),
      );

      return applicationStatus;
    } catch (error) {
      throw new KovoError('Error fetching application status')
        .addMetadata({
          userId: user?.userId,
        })
        .setError(error);
    }
  };

  return useQuery<ApplicationStatusResponse>({
    queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    queryFn: getApplicationStatus,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    /**
     * Generally, we don't want hooks and useQuery to have to have this manual enabled/disabled toggle
     * However, for this query I could not find a way to get it to stop running earlier than it should
     * (during the login pages), so this was the only solution I found for the time being.
     *
     * TODO: revisit this as part of continued efforts to clean up the contexts and try to synchronize them
     * together in a more maintainable fashion.
     */
    enabled: !!user,
    ...options,
  });
}

export default useApplicationStatus;
