import { Auth } from 'aws-amplify';
import { normalizeEmail } from 'helpers/utils';
import { KovoError } from 'libs/KovoError';
import { AuthErrorCode } from 'libs/signupHelpers/types';
import { useMutation } from 'react-query';

const resendSignUp = async (email: string) => {
  try {
    const normalizedEmail = normalizeEmail(email);

    await Auth.resendSignUp(normalizedEmail);
  } catch (error) {
    let resendSignupError = new KovoError('Error resending confirmation code', {
      error,
    }).setDefaultDisplayMessage(
      'Error resending confirmation code. Please try again.',
    );

    if (error instanceof Error) {
      switch (error.name) {
        case 'LimitExceededException':
          resendSignupError
            .setDisplayMessage(error.message)
            .setLogLevel('warn')
            .setCode(AuthErrorCode.LIMIT_EXCEEDED);
          break;
      }
    }

    throw resendSignupError;
  }
};

const useResendEmailVerificationCode = () => {
  return useMutation<void, KovoError, string>((email) => resendSignUp(email));
};

export default useResendEmailVerificationCode;
