import type { CognitoUser } from '@aws-amplify/auth';

export const PENDING_EMAIL_ATTRIBUTE_KEY = 'custom:pending_email';
export const PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY =
  'custom:pending_email_time';

export type AuthResult =
  | {
      cognitoUserConfirmed: true;
      cognitoUser: CognitoUser;
    }
  | {
      cognitoUserConfirmed: false;
      cognitoUser?: CognitoUser;
    };

export enum AuthErrorCode {
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  USERNAME_EXISTS = 'USERNAME_EXISTS',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  UNCONFIRMED_EMAIL_EXCEPTION = 'UNCONFIRMED_EMAIL_EXCEPTION',
  INVALID_CONFIRMATION_CODE = 'INVALID_CONFIRMATION_CODE',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
}
