import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';
import { KovoError } from 'libs/KovoError';

function useWelcomeCompleted() {
  const { identityId, username } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const setWelcomeCompleted = async () => {
    try {
      await API.post(
        'installments',
        '/installments/welcome',
        amplifyRequestContext(identityId, username),
      );
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error setting welcome completed').setError(error);
    }
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [USE_CURRENT_USER_QUERY_KEY],
    });
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    });
  };

  return useMutation(setWelcomeCompleted, { onSuccess });
}

export default useWelcomeCompleted;
