import { Auth } from 'aws-amplify';
import { KovoError, KovoUnauthenticatedError } from 'libs/KovoError';
import { AuthResult, AuthErrorCode } from './types';

/**
 * This function will sign in a user with the given email and password.
 */
export const signInUser = async (
  email: string,
  password: string,
): Promise<AuthResult> => {
  try {
    const cognitoUser = await Auth.signIn(email, password);

    return {
      cognitoUser: cognitoUser,
      cognitoUserConfirmed: true,
    };
  } catch (error) {
    if (error instanceof Error) {
      switch (error.name) {
        case 'NotAuthorizedException':
          throw new KovoUnauthenticatedError(error.message)
            .setError(error)
            .setCode(AuthErrorCode.NOT_AUTHORIZED)
            .setLogLevel('warn')
            .addMetadata({ email })
            .exposeMessage();
        case 'UserNotFoundException':
          throw new KovoUnauthenticatedError(error.message)
            .setError(error)
            .setCode(AuthErrorCode.USER_NOT_FOUND)
            .setLogLevel('warn')
            .addMetadata({ email })
            .exposeMessage();
        case 'UserNotConfirmedException':
          return {
            cognitoUserConfirmed: false,
          };
        case 'LimitExceededException':
          throw new KovoUnauthenticatedError(error.message)
            .setError(error)
            .setCode(AuthErrorCode.LIMIT_EXCEEDED)
            .setLogLevel('warn')
            .addMetadata({ email })
            .exposeMessage();
      }
    }

    throw new KovoError('Error signing in').setError(error);
  }
};
